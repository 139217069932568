@import "variables";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: $main-font;
  overflow-x: hidden;
  // padding-top: 80px;

  &.locked {
    overflow: hidden;
  }

  .-smallmarginright {
    margin-right: 5px;
  }
}

.main-content {
  overflow: hidden;
  margin-top: 100px;
  &.-nomargin {
    margin-top: 0;
  }
}

.border-black {
  border: 1px solid black;
}

.section-our-values {
  background-color: $color-lighter;
  padding-top: 60px;
}

.section-career {
  padding-top: 30px;
}

.animation-container {
  width: 100%;
  height: 0;
  position: relative;
  margin-bottom: 40px;
  z-index: -2;

  > div {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &.animation-about {
    padding-top: 110.73%;
  }

  &.animation-how-we-work {
    padding-top: 120%;

    > div {
      left: -150px;
    }
  }

  &.animation-our-story {
    padding-top: 100%;
    margin-top: -140px;
    margin-bottom: 200px;

    > div {
      left: -60px;
    }
  }

  &.animation-our-values {
    margin-top: 100px;
    margin-bottom: 0;
    padding-top: 60.4%;
    z-index: 1;
  }

  &.animation-career {
    padding-top: 42.29%;
    margin-bottom: 0;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .main-content {
    overflow: visible;
  }

  .section-about {
    margin-bottom: 40px;
  }

  .section-our-story {
    margin-top: -100px;
  }

  .section-our-values {
    margin-top: -100px;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: inherit;
      width: 100%;
      height: 100%;
      top: 0;
      right: 100%;
    }

    &:after {
      right: auto;
      left: 100%;
    }
  }

  .section-career {
    padding-top: 80px;
  }

  .animation-container {
    &.animation-our-story {
      margin-top: -250px;
      right: -50px;
    }

    &.animation-our-values {
      padding-top: 30.26%;
    }

    &.animation-how-we-work {
      > div {
        top: -50px;
        left: -200px;
      }
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

@import "ourvalues";
@import "ourstory";
@import "howwework";
@import "careers/careers";
@import "typography";
