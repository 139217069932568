@import "../../../assets/sass/variables";

.main-navbar {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  padding: 0;
  h1 {
    display: inline-block;
  }

  &.translucent {
    background: rgba(255, 255, 255, 0.9);
  }

  &.transparent {
    background: #050b4a;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  .main-menu-toggle {
    background: none;
    border: none;
    outline: none;

    img {
      width: 25px;
    }
  }
  .positions {
    margin-left: auto;
  }
}

.nav-link {
  color: black;

  :hover {
    color: black;
  }

  :focus {
    color: black;
  }
}

.navbar-link {
  color: black;
  margin-top: 1rem;
  margin-left: 1rem;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;

  > div {
    width: 120px;
    background-color: $color-lighter;
    border-radius: 50px;
    border: 2px solid #000;

    &:hover {
      color: $white;
      background-color: $color-violet;
    }
  }

  span {
    width: 100%;

    font-size: 14px;
    font-weight: 600;
    outline: none;
    text-transform: none;

    position: relative;
    top: 0.4rem;
  }
}

.btn-primary {
  color: black;
  &.-position {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    max-width: 200px;
    margin-right: 30px;
    height: 40px;
    margin-top: 9px;
    padding: 0;
    outline: none;
    text-transform: none;
  }

  &.-round {
    border-radius: 50px;
  }

  &.-blackborder {
    border: 2px solid #000;
  }

  &.-outlined {
    background: transparent;
    line-height: 16px;
  }
  &.-outlined-delete {
    line-height: 16px;
  }
}

.btn-primary:hover {
  color: white;
}

@media (min-width: 320px) {
  .navbar-link {
    margin-bottom: 1rem;
  }

  .nav {
    display: flex;
    justify-content: center;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .main-navbar {
    padding: 10px 0;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .main-navbar {
    padding: 20px 0;

    .main-menu-toggle {
      img {
        width: 30px;
      }
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
