@import "../../../assets/sass/variables";

.accordion {
  margin: 30px -15px 0 -15px;
}

.a-item {
  margin-bottom: 12px;

  .a-header {
    padding: 20px 15px;
    background-color: $white;
    color: #000;
    cursor: pointer;
    font-weight: 600;

    h4 {
      display: flex;
      font-size: 20px;
      margin: 0;
      justify-content: space-between;
    }

    &:hover {
      background-color: lighten($color-violet, 10%);
    }
  }

  .a-body {
    padding: 20px 15px;
    background-color: #ffffff;

    p {
      color: #000000;
      font-size: 14px;
    }

    h5 {
      color: #000000;
      text-transform: uppercase;
      font-size: 14px;
      margin: 20px 0 5px 0;
      letter-spacing: 5px;
      font-weight: 600;
    }

    ul {
      padding: 0;
      color: #000000;
      list-style: none;
      font-size: 14px;
      li {
        margin-bottom: 3px;
      }
    }

    a {
      color: $color-blue;
    }
  }
}

.accordion-button {
  &.-position {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    max-width: 200px;
    margin-right: 30px;
    height: 40px;
    margin-top: 9px;
    padding: 0;
    outline: none;
    text-transform: none;
  }

  &.-round {
    border-radius: 50px;
  }

  &.-blackborder {
    border: 2px solid #000;
  }

  &.-outlined {
    background: transparent;
    line-height: 16px;
  }
  &.-outlined-delete {
    line-height: 16px;
  }
}

.btn-danger,
.btn-info {
  &.-position {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    max-width: 200px;
    margin-right: 30px;
    height: 40px;
    margin-top: 9px;
    padding: 0;
    outline: none;
    text-transform: none;
  }

  &.-round {
    border-radius: 50px;
  }

  &.-blackborder {
    border: 2px solid #000;
  }

  &.-outlined {
    background: transparent;
    line-height: 16px;
  }
  &.-outlined-delete {
    line-height: 16px;
  }
}

.btn-info:hover {
  background-color: #4f52a3;
  color: white;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .accordion {
    margin: 30px 0 0 0;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
