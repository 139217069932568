.login-button {
  &.-position {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    max-width: 200px;
    margin-right: 30px;
    height: 40px;
    margin-top: 9px;
    padding: 0;
    outline: none;
    text-transform: none;
  }

  &.-round {
    border-radius: 50px;
  }

  &.-blackborder {
    border: 2px solid #000;
  }

  &.-outlined {
    background: transparent;
    line-height: 16px;
  }
  &.-outlined-default {
    background-color: #00dccb;
    line-height: 16px;
  }
}
.login-section {
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.login-center {
  margin-top: 20vh;
}

.login-purple:hover {
  background-color: #4f52a3;
  color: white;
}

@media (min-width: 768px) {
  .buttons {
    display: flex;
    justify-content: center;
  }
}
