.content {
  padding: 50px;
  background-color: #ebedfb;
}

.dashboard {
  height: 100vh;
  background-color: #ebedfb;
  overflow-x: auto;
  -ms-overflow-style: none;
}

.dashboard::-webkit-scrollbar {
  display: none;
}

.btn-primary {
  color: black;
  &.-position {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    max-width: 200px;
    margin-right: 30px;
    height: 40px;
    margin-top: 9px;
    padding: 0;
    outline: none;
    text-transform: none;
  }

  &.-round {
    border-radius: 50px;
  }

  &.-blackborder {
    border: 2px solid #000;
  }

  &.-outlined {
    background: transparent;
    line-height: 16px;
  }
  &.-outlined-delete {
    line-height: 16px;
  }
}

.btn-primary:hover {
  background-color: #4f52a3;
}
