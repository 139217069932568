/* Colors */
$color-accent: #6fddd2;
$color-light: #b5bbf3;
$color-lighter: #ebedfb;
$color-violet: #3e4181;
$color-blue: #1d206b;
$light-gray: #9093a3;
$white: #ffffff;

$text-paragraph: #1d206b;

$main-font: "Poppins", sans-serif;
