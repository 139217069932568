@import '../../../assets/sass/variables';

.description-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  h2 {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.3;
  }

  h3 {
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: rgba(0, 0, 0, 0.5);
  }

  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  &.landing {
    h2 {
      font-size: 34px;
    }
  }

  &.dark {
    h2, .link-default {
      color: #fff;
    }

    h3 {
      color: rgba(255, 255, 255, 0.5);
    }

    .link-default {
      &:before {
        background-color: #fff;
      }

      &:hover {
        color: #000;
      }
    }
  }
}

.section-image {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.link-default {
  text-transform: lowercase;
  color: #000;
  position: relative;
  align-self: flex-end;
  display: inline-block;
  margin-right: 108px;
  z-index: 10;

  &:before {
    content: "";
    width: 100px;
    height: 1px;
    background-color: #000;
    position: absolute;
    bottom: 10px;
    left: calc(100% + 8px);
  }

  &:after {
    content: "";
    width: 100%;
    height: 6px;
    background: $color-accent;
    position: absolute;
    left: 0;
    bottom: 2px;
    z-index: -2;
    transition: 0.14s;
  }

  &:hover {
    text-decoration: none;
    color: inherit;

    &:after {
      height: 18px;
    }
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .description-block {
    h2 {
      font-size: 36px
    }

    p {
      font-size: 20px
    }
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .description-block {
    &.landing {
      text-align: center;

      .link-default {
        align-self: center;
        margin-right: 0;
      }
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .description-block {
    justify-content: center;

    &.landing {
      text-align: left;

      .link-default {
        align-self: flex-start;
      }
    }

    .link-default {
      align-self: flex-start;
    }

    &.center {
      text-align: center;

      .link-default {
        align-self: center;
      }
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .description-block {
    &.landing {
      margin-top: 50px;

      h2 {
        font-size: 60px;
        line-height: 1.2;
      }
    }
  }
}