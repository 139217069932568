.text-red{
    color: red !important;

    >button{
    color: red !important;

    }
}

.text-green{
    color: green !important;
    >button{
    color: green !important;

    }
}